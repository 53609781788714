const domReady = function (funct) {
  document.addEventListener(
    'DOMContentLoaded',
    function () {
      if (typeof funct === 'function') {
        funct()
      }
    },
    false
  )
}

// 获取语言
const getLanguage = function () {
  let language = getUrlParam('language')
  if (!language) {
    const lan = navigator && navigator.language
    const arr = breakStr(lan, '-')
    language = language ? language : arr[0] ? arr[0].toLowerCase() : 'en'
  }
  return language
}

// 获取国家
const getCountry = function () {
  let country = getUrlParam('country')
  if (!country) {
    const lan = navigator && navigator.language
    const arr = breakStr(lan, '-')
    country = country ? country : arr[1] ? arr[1].toUpperCase() : 'US'
  }
  return country
}

const getUrlParam = function (name) {
  const url = window.location.search
  const re = new RegExp('' + name + '=([^&?]*)', 'ig')
  return url.match(re) ? url.match(re)[0].substring(name.length + 1) : null
}

const breakStr = function (str, delim) {
  const arr = str.split(delim)
  return arr.map(function (val) {
    return val.trim()
  })
}

const getRandom = function (start, end) {
  const num = end - start + 1
  return Math.floor(Math.random() * num + start)
}

const getTimeStr = function (date) {
  const time = new Date(date).getTime()
  const cur_time = new Date().getTime()
  const dur = cur_time - time
  const minute = Math.floor(dur / 60000)
  const hour = Math.floor(minute / 60)
  const day = Math.floor(hour / 24)
  if (minute <= 0) {
    return 'Just now'
  } else if (minute < 60) {
    return minute + ' minutes ago'
  } else if (hour < 24) {
    return hour + ' hours ago'
  } else {
    return day + ' days ago'
  }
}

const isPCorMobile = function () {
  const ua = navigator.userAgent.toLowerCase()
  const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone",
    "iPad", "iPod", "IOS", "webOS", "BlackBerry",
    "phone", "pad", "pod", "Mobile", "IEMobile", "MQQBrowser",
    "JUC", "Fennec", "wOSBrowser", "BrowserNG", "WebOS"]
  for (var i = 0; i < agents.length; i++) {
    if (ua.indexOf(agents[i].toLowerCase()) >= 0) {
      return false
    }
  }
  return true
}

const tapHandler = function (node, callback, arg) {
  if (isPCorMobile()) {
    node.addEventListener('click', () => {
      callback && callback.call({}, arg)
    })
    return
  }

  const def = {
    time: 250,
    offset: 10
  }
  let st, sx, sy

  node.addEventListener('touchstart', (e) => {
    const touch = e.targetTouches[0]
    st = e.timeStamp
    sx = touch.pageX
    sy = touch.pageY
  }, false)

  node.addEventListener('touchend', (e) => {
    const touch = e.changedTouches[0]
    if (
      e.timeStamp - st <= def.time &&
      Math.abs(touch.pageX - sx) <= def.offset &&
      Math.abs(touch.pageY - sy) <= def.offset
    ) {
      callback && callback.call({}, arg)
    }
    st = sx = sy = 0
  }, false)
}

export { domReady, getLanguage, getCountry, getRandom, getTimeStr, tapHandler }