import { getUserId } from '@/utils'
import { channel, api_key, source_id, view_id, placements } from '@/config'

const taboola_url = `https://api.taboola.com/2.0/json/${channel}/recommendations.get`
const request_body = {
  placements,
  app: {
    type: 'MOBILE',
    apiKey: api_key,
    origin: 'CLIENT',
    name: channel,
    mobileData: {
      device: {
        locale: navigator && navigator.language,
        ui: navigator && navigator.language.substring(0, 2)
      }
    }
  },
  source: {
    type: 'HOME',
    id: source_id,
    url: 'http://www.' + channel + '.com/'
  },
  view: { id: view_id },
  user: {
    session: 'init',
    agent: navigator && navigator.userAgent,
    device: getUserId(180)
  }
}

export { request_body, taboola_url }
