import { tapHandler } from '@/utils'
import { gtagAnalytics } from '@/analytics'

class RenderSearch {
  createDom = function (frag) {
    this.s_search = document.createElement('div')
    this.s_search.classList.add('search')

    this.s_input = document.createElement('input')
    this.s_input.setAttribute('type', 'text')
    this.s_input.setAttribute('placeholder', 'Search')
    this.s_search.appendChild(this.s_input)

    this.s_btn = document.createElement('div')
    this.s_btn.classList.add('search-btn')
    this.s_search.appendChild(this.s_btn)

    frag.appendChild(this.s_search)
  }

  initSearch = function () {
    // 点击搜索图标
    tapHandler(this.s_btn, () => {
      gtagAnalytics('search')
      location.href = 'https://www.google.com/search?q=' + this.s_input.value
      // location.href = 'http://s.newsearchers.com/search/angel?q=' + this.s_input.value
    })

    // 点击键盘搜索
    this.s_input.addEventListener('keyup', (e) => {
      if (e.keyCode == 13) {
        gtagAnalytics('search')
        location.href = 'https://www.google.com/search?q=' + this.s_input.value
        // location.href = 'http://s.newsearchers.com/search/angel?q=' + this.s_input.value
      }
    })
  }
}

const renderSearchDom = function (node) {
  const parent_dom = document.querySelector(node)
  const frag = document.createDocumentFragment()
  const search = new RenderSearch()
  search.createDom(frag)
  search.initSearch()
  parent_dom.appendChild(frag)
}

export { renderSearchDom }