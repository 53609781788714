const getUserId = function (time) {
  let cookieVal
  let cookieBrk = document.cookie.match(new RegExp('(^| )' + 'deviceId' + '=([^;]*)(;|$)'))
  cookieVal = cookieBrk ? cookieBrk[2] : null
  if (cookieVal === null || cookieVal === '') {
    setCookie('deviceId', getUUID(), {
      expires: time
    })
    cookieVal = getCookie('deviceId')
  }
  return cookieVal
}

const getUUID = function () {
  let time = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (item) {
    var e = (time + 16 * Math.random()) % 16 | 0
    return (time = Math.floor(time / 16)), ('x' == item ? e : (3 & e) | 8).toString(16)
  })
}

const setCookie = function (name, value, options) {
  if (typeof value != 'undefined') {
    options = options || {}
    //如果值为null, 删除cookie
    if (value === null) {
      value = ''
      options = {
        expires: -1
      }
    }
    //设置有效期
    var expires = ''
    if (options.expires && (typeof options.expires == 'number' || options.expires.toGMTString)) {
      var date
      if (typeof options.expires == 'number') {
        date = new Date()
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000)
      } else {
        date = options.expires
      }
      expires = ';expires=' + date.toGMTString()
    }
    var path = options.path ? ';path=' + options.path : ''
    var domain = options.domain ? ';domain=' + options.domain : ''
    var secure = options.secure ? ';secure' : ''
    //设置cookie
    document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('')
  }
}

const getCookie = function (name) {
  if (document.cookie.length > 0) {
    var start = document.cookie.indexOf(name + '=')
    if (start != -1) {
      start = start + name.length + 1
      var end = document.cookie.indexOf(';', start)
      if (end == -1) {
        end = document.cookie.length
      }
      return decodeURIComponent(document.cookie.substring(start, end))
    }
  }
  return ''
}

export { getUserId, getCookie }