import { createApp } from '@/pages/home'
import { install } from 'ga-gtag'
import { gtagAnalytics } from '@/analytics'

import '@/assets/css/common.css'
import '@/assets/css/home.css'

install('G-L2D4ZWT7D7', { 'send_page_view': false })
gtagAnalytics('loading')
createApp()
