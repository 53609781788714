import { renderFromReal, lazyLoading } from '@/pages/home/taboola'
import { renderSearchDom } from '@/pages/home/render/render_search'
import { sendAjax, getUserId } from '@/utils'
import { channel, base_url } from '@/config'

const createApp = function () {
  // 渲染搜索
  renderSearchDom('#insert-search')
  // 实时数据
  renderFromReal()
  // 滑动加载
  lazyLoading()
  // 记录
  sendAjax({
    type: 'GET',
    url: 'https://analytics.sve.cc/v1/report/page',
    data: {
      type: 'page', // visit为Ads请求访问时的事件，click为Ads点击时的事件
      pid: channel, // 渠道号ID
      uid: getUserId(180), // 用户ID
      dm: base_url, // document.domain
      qs: window.location.pathname + window.location.search + window.location.hash,
      lc: navigator && navigator.language, // (navigator && navigator.language)
      ux: window.screen.width + '*' + window.screen.height, // screen.width+'x'+screen.height
      tz: new Date().getTimezoneOffset() / 60,
      ir: self !== top ? 1 : 0,
      ct: Math.floor(new Date().getTime() / 1000)
    },
    headers: { 'Content-Type': 'text/plain;charset=utf-8' }
  })
}

export { createApp }
