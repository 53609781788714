// 设置本地存储
const localStorageSet = function (name, value, expires = 60) {
  const storages = {}
  storages[name] = {
    value,
    expires: expires * 60 * 1000 + +new Date(),
    date: formatTime(expires * 60 * 1000 + +new Date())
  }
  localStorage.setItem(name, JSON.stringify(storages))
}
// 提取本地存储
const localStorageGet = function (name) {
  const storages = JSON.parse(localStorage.getItem(name))
  try {
    if (!storages[name]) {
      return -1
    }
    if (+new Date() > storages[name].expires) {
      // 存在但过期
      localStorageRemove(name)
      return -1
    }
    if (!storages[name].value) {
      return -1
    }
    return storages[name].value
  } catch (error) {
    console.log('获取本地存储数据失败：\n', error)
    return -1
  }
}
// 移除本地存储
const localStorageRemove = function (name) {
  const storages = JSON.parse(localStorage.getItem(name))
  try {
    delete storages[name].value
    if (!storages[name].value) {
      // 缓存字段为空对象时，删除该字段
      localStorageClear(name)
      localStorage.setItem(name, JSON.stringify(storages))
      return true
    }
  } catch (error) {
    console.log('[ControlStorage] the error message: remove field failed\n', error)
  }
}

const localStorageClear = function (name) {
  localStorage.removeItem(name)
}

const formatTime = function (time) {
  const date = new Date(time)
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let min = date.getMinutes()

  if (month < 10) month = '0' + month
  if (day < 10) day = '0' + day
  if (hour < 10) hour = '0' + hour
  if (min < 10) min = '0' + min

  return `${year}.${month}.${day} ${hour}:${min}`
}

export { localStorageSet, localStorageGet, localStorageRemove }
