import { sendAjax, localStorageSet, localStorageGet, getTimeStr, getUserId } from '@/utils'
import { renderNewsNode } from '../render/render_news'
import { request_body, taboola_url } from './config'
import { channel, base_url } from '@/config'
import { clickTimesStore } from '@/store'

let news_count = 0
let lazy_load = false
let count = 0
let placement_count = 1

const formatPlacements = function (placements) {
  const feed_list = []
  const news_arr = []
  const ads_arr = []
  for (let i = 0; i < placements.length; i++) {
    let placement = placements[i]
    if (placement.list.length > 0) {
      sendAjax({ url: placement.events.visible })

      // 监听
      const aid = []
      const bd = []
      for (let j = 0; j < placement.list.length; j++) {
        const id = placement.list[j].id.split('~~')[2]
        // 记录时间
        clickTimesStore.timeWrite({
          id,
          time: new Date().getTime()
        })
        //
        aid.push(id)
        bd.push(placement.list[j].branding)
        placement.list[j].placement = placement.name
      }

      // 记录
      sendAjax({
        type: 'GET',
        url: 'https://analytics.sve.cc/v1/report',
        data: {
          type: 'call', // call为Ads请求访问时的事件，click为Ads点击时的事件
          pid: channel, //渠道号ID
          pm: placement.name,
          uid: getUserId(180), //用户ID
          aid: aid.join(','), //软文ID
          bd: bd.join(','), //文章品牌
          dm: base_url, // document.domain
          qs: window.location.pathname + window.location.search + window.location.hash,
          lc: navigator && navigator.language, // (navigator && navigator.language)
          ux: window.screen.width + '*' + window.screen.height, // screen.width+'x'+screen.height
          tz: new Date().getTimezoneOffset() / 60,
          ir: self !== top ? 1 : 0,
          ct: Math.floor(new Date().getTime() / 1000)
        },
        headers: { 'Content-Type': 'text/plain;charset=utf-8' }
      })
      //

      if (placement.name.startsWith('AD')) {
        placement.list[0].created = placement.list[0].origin
        ads_arr.push(...placement.list)
      } else if (placement.name.endsWith('Thumbnails-1-1')) {
        request_body.placements[0].thumbnail = { width: 215, height: 145 }
        placement.list[0].created = getTimeStr(placement.list[0].created)
        feed_list.push(...placement.list)
      } else {
        request_body.placements[0].name = `Editorial Trending Thumbnails-${placement_count}-1`
        request_body.placements[1].name = `Editorial Trending Thumbnails-${placement_count}-2`
        request_body.placements[2].name = `AD Editorial Trending Thumbnails-${placement_count}-1`
        request_body.placements[3].name = `Editorial Trending Thumbnails-${placement_count}-3`
        request_body.placements[4].name = `Editorial Trending Thumbnails-${placement_count}-4`
        request_body.placements[5].name = `AD Editorial Trending Thumbnails-${placement_count}-2`
        placement.list[0].created = getTimeStr(placement.list[0].created)
        news_arr.push(...placement.list)
      }
    }
  }
  // 合并
  feed_list.push(...news_arr)
  // 标记广告位置
  const location = insertLocation(feed_list, 2)
  // 倒序插入广告
  for (let j = feed_list.length - 1; j >= 0; j--) {
    if (location.length > 0 && j === location[location.length - 1] - 1) {
      const ads = ads_arr.pop()
      const index = location.pop()
      if (ads) {
        feed_list.splice(index, 0, ads)
      }
    }
  }
  return feed_list
}

const insertLocation = function (list, step) {
  const location = []
  for (let i = 0; i < list.length; i += step) {
    location.push(i + step)
  }
  return location
}

const formatContentList = function (list) {
  const new_arr = []
  for (let i = 0; i < list.length; i++) {
    new_arr.push({
      placement: list[i].placement,
      image_url: list[i].thumbnail[0].url,
      disc: list[i].branding,
      link_url: list[i].url,
      title: list[i].name,
      ads_id: list[i].id,
      created: list[i].created
    })
  }
  return new_arr
}

const renderFromReal = function () {
  placement_count++
  sendAjax({
    type: 'POST',
    url: taboola_url,
    data: request_body,
    headers: { 'Content-Type': 'text/plain;charset=utf-8' }
  }).then((res) => {
    const result = JSON.parse(res)
    request_body.user.session = result.user.session
    if (result.placements.length > 0) {
      // 数据处理 placements => list
      const new_list = formatContentList(formatPlacements(result.placements))
      // 数据统计
      news_count += new_list.length
      // 渲染页面
      renderNewsNode('#insert-news', new_list)
    }
  },
    (err) => console.error(`获取新闻出现错误：${err}`)
  )
    .finally(() => {
      if (news_count < 12 && count < 4) {
        count++
        renderFromReal()
      } else {
        news_count = 0
        lazy_load = false
      }
    })
}

const lazyLoading = function () {
  window.addEventListener('scroll', () => {
    const news_dom = document.querySelectorAll('.news-wrapper')
    const target_dom = news_dom[news_dom.length - 1]
    const client_rect = target_dom.getBoundingClientRect()
    if (client_rect.top - window.innerHeight < 300 && !lazy_load) {
      lazy_load = true
      renderFromReal()
    }
  }, false)
}

export { renderFromReal, lazyLoading }
