import { gtagAnalytics } from '@/analytics'
import { sendAjax, getUserId } from '@/utils'
import { channel, base_url } from '@/config'
import { clickTimesStore } from '@/store'

class RenderNews {
  constructor(data_list) {
    for (let item in data_list) {
      this[item] = data_list[item]
    }
  }

  createNode = function (frag) {
    this.a_link = document.createElement('a')
    this.a_link.setAttribute('href', this.link_url)
    this.a_link.classList.add('news-wrapper')

    this.bg_img = document.createElement('div')
    this.bg_img.classList.add('bg-img')
    this.bg_img.style.backgroundImage = `url('${this.image_url}')`
    this.a_link.appendChild(this.bg_img)

    this.a_content = document.createElement('div')
    this.a_content.classList.add('content')
    this.a_link.appendChild(this.a_content)

    this.a_title = document.createElement('p')
    this.a_title.innerHTML = this.title
    this.a_content.appendChild(this.a_title)

    this.a_info = document.createElement('p')
    this.a_content.appendChild(this.a_info)

    this.a_disc = document.createElement('span')
    this.a_disc.innerHTML = this.disc
    this.a_info.appendChild(this.a_disc)

    this.a_time = document.createElement('span')
    this.a_time.innerHTML = this.created
    this.a_info.appendChild(this.a_time)

    frag.appendChild(this.a_link)
  }

  eventListen = function () {
    this.a_link.addEventListener('click', (e) => {
      e.preventDefault()
      gtagAnalytics('click')
      const aid = this.ads_id.split('~~')[2] || ''
      const clickTime = new Date().getTime()
      let callTime = 0
      let diffTime = 0
      for (let i = 0; i < clickTimesStore.times.length; i++) {
        if (clickTimesStore.times[i].id === aid) {
          callTime = clickTimesStore.times[i].time
        }
      }
      diffTime = Math.floor((clickTime - callTime) / 1000)
      // 记录
      sendAjax({
        type: 'GET',
        url: 'https://analytics.sve.cc/v1/report',
        data: {
          type: 'click', // visit为Ads请求访问时的事件，click为Ads点击时的事件
          pid: channel, //渠道号ID
          pm: this.placement || '',
          uid: getUserId(180), //用户ID
          aid, //软文ID
          bd: this.title || '', //文章品牌
          dm: base_url, // document.domain
          qs: window.location.pathname + window.location.search + window.location.hash,
          lc: navigator && navigator.language, // (navigator && navigator.language)
          ux: window.screen.width + '*' + window.screen.height, // screen.width+'x'+screen.height
          tz: new Date().getTimezoneOffset() / 60,
          ir: self !== top ? 1 : 0,
          ct: Math.floor(new Date().getTime() / 1000),
          df: diffTime
        },
        headers: { 'Content-Type': 'text/plain;charset=utf-8' }
      }).finally(() => {
        window.location.href = this.link_url
      })
    }, false)
  }
}

const renderNewsNode = function (node, data_list) {
  const parent_node = document.querySelector(node)
  const frag = document.createDocumentFragment()
  let render_list = []
  for (let i = 0; i < data_list.length; i++) {
    render_list[i] = new RenderNews(data_list[i])
    render_list[i].createNode(frag)
    render_list[i].eventListen()
  }
  parent_node.appendChild(frag)
}

export { renderNewsNode }