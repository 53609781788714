// 发送AJAX请求
const sendAjax = function (options) {
  return new Promise((resolve, reject) => {
    options = options || {}

    let data = JSON.stringify(options.data)
    let params = options.url.indexOf('?') >= 0 ? '&' + formatParams(options.data) : '?' + formatParams(options.data)

    // 默认
    options.type = (options.type || 'GET').toUpperCase()
    options.dataType = options.dataType || 'json'
    options.async = options.async || true

    // 创建XMLHttpRequest对象
    let xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')

    if (options.type == 'GET') {
      xhr.open('GET', options.url + params, options.async)
      if (options.headers) {
        for (let k in options.headers) {
          xhr.setRequestHeader(k, options.headers[k])
        }
      }
      xhr.send(null)
    } else if (options.type == 'POST') {
      xhr.open('POST', options.url, options.async)
      if (options.headers) {
        for (let k in options.headers) {
          xhr.setRequestHeader(k, options.headers[k])
        }
      }
      xhr.send(data)
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        const status = xhr.status
        if (status >= 200 && status < 300) {
          const content = xhr.responseText || xhr.responseXML
          resolve(content)
        } else {
          reject(status)
        }
      }
    }
  })
}

// 格式化URL参数
const formatParams = function (data) {
  var arr = []
  for (let param in data) {
    arr.push(encodeURIComponent(param) + '=' + encodeURIComponent(data[param]))
  }
  return arr.join('&')
}

export { sendAjax }